import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0,1,2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: '4EZ',
    lpAddresses: {
      97: '0x1bb8c6d13f6bd7778b024794d19ef8c45f178955',
      56: '0x1bb8c6d13f6bd7778b024794d19ef8c45f178955',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
 
 
  {
    pid: 1,
    lpSymbol: '4EZ-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x5cab0112de9317b8ce5c882bdd94cd5a341dcc70',
    },
    token: tokens.rz,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x5ada794216bedd947db451e6af7c5d923cdb7c5d',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  
   {
    pid: 3,
    lpSymbol: '4EZ-4RZ LP',
    lpAddresses: {
      97: '',
      56: '0x1489ea87ac8ce5482b5f7bca5dc376ae988b3a7d',
    },
    token: tokens.rz,
    quoteToken: tokens.frz,
  },
  
  {
    pid: 4,
    lpSymbol: 'UST-4RZ LP',
    lpAddresses: {
      97: '',
      56: '0xca54cc1ca16859a664c51e03b7bb2ae28fa20d09',
    },
    token: tokens.ust,
    quoteToken: tokens.frz,
  },
  
   {
    pid: 5,
    lpSymbol: '4RZ-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x2ff97ef3474c36490424437b3f40a7a67888ed4f',
    },
    token: tokens.frz,
    quoteToken: tokens.wbnb,
  },
  
   {
    pid: 6,
    lpSymbol: 'MyroInu-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xf967ca631fb9914372bbf72aa017d241b7153d60',
    },
    token: tokens.myroinu,
    quoteToken: tokens.wbnb,
  },
  
  
  
   
	  
	
// finished last  2  
    

]

export default farms
