import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [

  {
    sousId: 0,
    stakingToken: tokens.rz,
    earningToken: tokens.rz,
    contractAddress: {
      97: '0x8ff349ffdc7ddc26233d5e1ea161b98094341acc',
      56: '0x8ff349ffdc7ddc26233d5e1ea161b98094341acc',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '30',
    sortOrder: 1,
    isFinished: false,
  },
  
  {
    sousId: 1,
    stakingToken: tokens.frz,
    earningToken: tokens.rz,
    contractAddress: {
      97: '',
      56: '0x73997b7abe5dc55ac94d75b6323d4b2ccbc2514b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '69',  //  237.5 token 
    },
  
]

export default pools
